<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";

// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "เพิ่มช่องจอด",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      // tableData: tableData,

      title: "เพิ่มช่องจอด",
      items: [
        {
          text: "ข้อมูลหลัก",
        },
        {
          text: "ข้อมูลสาขาผู้แทนจำหน่าย",
        },
        {
          text: "ช่องจอด",
          href: "/branch-parking-slot",
        },
        {
          text: "เพิ่่มช่องจอด",
          active: true,
        },
      ],
      overlayFlag: false,
      selected: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      branchMaster: [],
      totalRecords: 0,
      isHidden: false,

      // selectMode: "single",
      userBranchId: "",
      branchId: "",
      //   payMethCode: "",
      modelCode: "",
      manageBranch: "",
      shortNameEn: "",
      shortNameTh: "",
      nameTh: "",

      nameEn: "",
      payMethod: "",
      submitform: false,
      errormessage: "กรุณากรอกข้อมูลให้ครบ",

      userBranch: [],
      filterOn: [],
      rows: [],
      sortBy: "age",
      sortDesc: false,
      code:"",
      typeOption: [
          {
              tId: "PART",
              nameTh: "อะไหล่"
          },
          {
              tId: "VEHICLE",
              nameTh: "ยานยนต์"
          }
      ],
      deptOption:[
          {
              dId: "GS",
              nameTh: "GS"
          },
          {
              dId: "BP",
              nameTh: "BP"
          }
      ],
      type:'',
      dept:''
    };
  },
  validations: {
    nameTh: {
      required,
    },

    nameEn: {},
    branchId:{
        required
    },
    type: {
        required
    },
    code:{}


  },

  computed: {
    /**
     * Total no. of records
     */
    // rows() {
    //   return this.rows.length;
    // },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item);
    });
    this.branchMaster = user.userAccessBranch;
    this.userBranchId = user.branchId;
    this.userBranch = arrayBranch;
    this.totalRows = this.rows.total;
  },
  created() {},
  methods: {
    /**
     * Search the table data with search input
     */

    tooltipForm() {
      // console.log(this.$v)
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.$invalid != true) {
        this.submitDfi();
      }
    },
    submitDfi: function() {
      this.overlayFlag = true; //skeleton true
      useNetw
        .post("api/branch-parking-slot/store", {
        //   modelCode: this.modelCode,
          nameTh: this.nameTh,
          nameEn: this.nameEn,
          branchId: this.branchId.branchId,
          type: this.type.dId,


        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({
            name: "branch-parking-slot",
            params: {
              pslotId: response.data.pslotId,
            },
          });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
          this.overlayFlag = false; //skeleton true
        });
    },
  },
  middleware: "authentication",
};
</script>
<style>
.modal-custom .modal-dialog {
  max-width: 95%;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-overlay
      :show="overlayFlag"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                  <div class="row">
                    <div class="col-4">
                      <!-- <div class="mb-3 position-relative">
                        รหัสช่องจอด <br />
                        <input
                          type="text"
                          class="form-control"
                          placeholder="รหัสช่องจอด"
                          v-model="modelCode"
                          :class="{
                            'is-invalid': submitform && $v.modelCode.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.modelCode.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.modelCode.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div> -->
                      <code>* </code>สาขา :
                      <multiselect
                        v-model="branchId"
                        :options="userBranch"
                        label="nameTh"
                        :class="{
                          'is-invalid':
                            submitform && $v.branchId.$error,
                        }"
                      >
                      </multiselect>
                      <div
                        v-if="submitform && $v.branchId.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.branchId.required">{{
                          errormessage
                        }}</span>
                      </div>
                    </div>
                    <!-- <div class="col-4">
                      <div class="mb-3 position-relative">
                        รหัสช่องจอด :
                        <b-form-input
                          v-model="code"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.code.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.code.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.code.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div> -->
                    <div class="col-4">
                      <div class="mb-3 position-relative">
                        <code> * </code>ชื่อ (ไทย):
                        <b-form-input
                          v-model="nameTh"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.nameTh.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.nameTh.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.nameTh.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="mb-3 position-relative">
                        ชื่อ (อังกฤษ):
                        <b-form-input
                          v-model="nameEn"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.nameEn.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.nameEn.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.nameEn.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-4">
                      <div class="mb-3 position-relative">
                        <code>* </code>ประเภท :
                        <multiselect
                          v-model="type"
                          :options="typeOption"
                          label="nameTh"
                          :class="{
                            'is-invalid': submitform && $v.type.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.type.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.type.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div> -->
                    <div class="col-4">
                      <div class="mb-3 position-relative">
                        <code>* </code>ใช้กับ :
                        <multiselect
                          v-model="type"
                          :options="deptOption"
                          label="nameTh"
                          :class="{
                            'is-invalid': submitform && $v.type.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.type.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.type.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-1"></div>

                    <div class="col-12 col-sm-12 col-md-12">
                      <label for=""> </label><br />
                      <b-button
                        class="btn float-end m-1"
                        variant="success"
                        @click="tooltipForm"
                      >
                        บันทึก
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </Layout>
</template>
